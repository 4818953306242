import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
  useMemo,
} from 'react';
// import Badge from '@material-ui/core/Badge';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withNamespaces } from 'react-i18next';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  Slide,
  AppBar,
  useScrollTrigger,
  List,
  ListItem,
  SwipeableDrawer,
} from '@material-ui/core';

import { Image } from '@sitecore-jss/sitecore-jss-react';
import DctSvgIcon from '../../../components/atoms/Svg';
import cookie from '../../../utils/cookie';

import { canUseDOM } from '../../../utils/canUseDOM';
import Container from '../../../components/molecules/Container';
import Grid from '../../../components/molecules/Grid';
import Typography from '../../../components/atoms/Typography';
import Link from '../../../components/atoms/Link';
import Button from '../../../components/atoms/Button';
import LanguageSelector from '../../../components/atoms/LanguageSelector';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  BurgerIconBlack,
  BurgerIconWhite,
} from '../../../../styles/svgIcons/BurgerIcon';
import { updateSearchData } from '../Search/redux/actions';
import {
  getFavoritesInNavData,
  getFavoritesInNavAllData,
} from '../Filters/redux/actions';
import { searchData } from '../Search/redux/selectors';
import { filteredData } from '../Filters/redux/selectors';

import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { getDictionaryText } from '../../../utils/getDictionaryText';

import useStyles from './style';
import {
  COMPONENT_CONTAINER,
  COMPONENT_SLIDER,
  COMPONENT_HEADER,
  COMPONENT_GRID_CONTAINER,
  COMPONENT_GRID_BLOCK,
  COMPONENT_LOGO,
  COMPONENT_MOBILE_NAVIGATION,
} from './locators';
import { HOME_PAGE_ROUTE_NAME, EVENT_TYPE } from '../../../../constants';
import { COMPONENT_NAME as SearchComponentName } from '../Search/locators';
import { EXPLORE_MAP_TEMPLATE_ID } from '../ExploreMap/locators';
import { analyticsData } from '../../../../../src/constants/Analytics/analytics-data';
import { pushAnaylyticsData } from '../../../../../src/utils/analytics';
import withErrorBoundary from '../../../utils/HOCs/withErrorBoundary';
import clsx from 'clsx';
import { getSessionStorage } from '../../../../utils/sessionStorage';
import { pageContentType } from '../../../utils/getContentType';
import FavoriteContext from './../../../../context/favorite/FavoriteContext';

import FavoriteSection from '../../../components/molecules/FavoriteSection';
import Badge from '../../../components/atoms/Badge';
import { get } from 'lodash';

import { getLocalStorage } from './../../../../utils/localStorage';
import { getCurrentPageUrl } from '../../../utils/getPageUrl';
import { useDispatch } from 'react-redux';
import {
  setViewAllFavLink,
  setPreviewItineraryLink,
} from '../../../containers/common/Filters/redux/actions';
import Picture from '../../../components/atoms/Picture';
import ItineraryLoaderBg from '../../../../assets/images/loader-bg.png';

const HeaderComponent = props => {
  const {
    fields,
    sitecoreContext,
    rendering,
    updateSearchData,
    searchData,
    params,
    t: dictionary,
  } = props;
  const classes = useStyles({ favouriteBasket });
  const {
    logoLink,
    isSearchEnabled,
    adCalenderLogo,
    enabledFavorite,
    loadMoreFavoritesCta,
    defaultLoadFavoriteItems,
  } = fields || '';
  let { logo, alternateLogo, exploreMapLink } = fields || '';
  const contentType = pageContentType(props).toLowerCase();
  if (contentType === EVENT_TYPE) {
    logo = { ...adCalenderLogo };
  }
  const isMobile = useMediaQuery('(max-width:1023px)');
  const {
    language,
    route,
    pageLanguageVersions,
    VADStaticRoute,
  } = sitecoreContext;
  const pageName = route && route.name;
  const isItineraryMap = pageName === 'itinerary-map';
  const templateId = route && route.templateId;
  const isAdLogoWhite = get(route, 'fields.eventAdCalenderLogo', false)
  const isExploreMapTemplateID =
    templateId && templateId === EXPLORE_MAP_TEMPLATE_ID;
  const isSearchPage =
    pageName && pageName.toLowerCase() === SearchComponentName.toLowerCase();
  const isSearchOpen = searchData.data;
  const [languageSelectorFlag, setLanguageSelectorFlag] = useState(false);
  const [favoriteSelectorFlag, setFavoriteSelectorFlag] = useState(false);
  const [hasFavouriteBasket, setHasFavouriteBasket] = useState(false);
  const [favouriteBasket, setfavouriteBasket] = useState(null);
  const [favoritesList, setFavoritesList] = useState([]);
  const [totalFavoriteCount, setTotalFavoriteCount] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [enableSearch, setEnableSearch] = React.useState(false);
  const [hideHeader, setHideHeader] = React.useState(true);
  const [itineraryPreviewLink, setItineraryPreviewLink] = useState('');
  const appBarAnimate = useRef(null);
  const headerAppBar = useRef(null);
  const headerAppBarSpacer = useRef(null);
  const { favorites } = useContext(FavoriteContext);

  const customStyles = {
    headerPosition: isItineraryMap ? 'itineraryPosition' : '',
    megaMenuHeight: isItineraryMap ? 'itinerayMenu' : '',
    headerHide: isItineraryMap ? 'hide' : '',
  };

  const dispatch = useDispatch();
  const placeholders = get(VADStaticRoute, 'sitecore.route.placeholders', '');
  const vadHeader = get(placeholders, 'vad-header', []);

  let isHeaderTransparent = false;
  let headerPosition = 'fixed';
  // const isHomePage =
  //   pageName && pageName.toLowerCase() === HOME_PAGE_ROUTE_NAME.toLowerCase();
  const isHomePage = params.HeaderTransparent || false
  let classNameHeader = classes.headerWrapper;
  const scrollTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 20,
  });

  const trigger = useScrollTrigger({
    threshold: 1,
  });

  useEffect(() => {
    const favouriteBasket = vadHeader.find(
      item => item.componentName === 'FavoriteBasket'
    );
    const favIsActive = get(
      favouriteBasket,
      'fields["Is Active"].value',
      false
    );
    setHasFavouriteBasket(Boolean(favouriteBasket) && favIsActive);
    setfavouriteBasket(favouriteBasket);
    setEnableSearch(true);
    setItineraryPreviewLink(favouriteBasket?.fields?.itineraryMapCTA);
    //Fav Page Link is required in many atomic components that's why we have add a dispatch for it
    dispatch(setViewAllFavLink(favouriteBasket?.fields?.CTA));
    dispatch(setPreviewItineraryLink(favouriteBasket?.fields?.itineraryMapCTA));
  }, [vadHeader]);

  // create a useEffect for headerAppBar to get its height and set it on headerAppBarSpacer
  const appointHeaderAppBarHeight = () => {
    if (canUseDOM && headerAppBar && headerAppBar.current) {
      const headerAppBarHeight = headerAppBar.current.clientHeight;
      if (headerAppBarSpacer && headerAppBarSpacer.current) {
        headerAppBarSpacer.current.style.height = `${headerAppBarHeight}px`;
      }
    }
  };
  useEffect(() => {
    appointHeaderAppBarHeight();
    // also update on resize
    window.addEventListener('resize', () => {
      appointHeaderAppBarHeight();
    });
  }, [headerAppBar]);

  // set fav mobile bar height into css
  // set fav box bot bar height into css
  const mobFavBarRef = useCallback(node => {
    if (node !== null) {
      document.documentElement.style.setProperty(
        '--favMobileBar',
        `${node.clientHeight}px`
      );
    }
  }, []);

  const [scrollPosition, setScrollPosition] = useState(0);
  const [lastScrollPosition, setLastScrollPosition] = useState(0);
  const handleScroll = () => {
    if (canUseDOM) {
      const position = window.pageYOffset;
      setScrollPosition(position);
    }
  };

  const wait = ms => new Promise(resolve => setTimeout(resolve, ms));

  const handleLoader = async () => {
    const body = document.getElementsByTagName('body');
    body[0].classList.add('overflow-hidden');
    const divElement = document.querySelector('.hide');
    await wait(4000);
    setHideHeader(false);
    body[0].classList.remove('overflow-hidden');
    document.getElementById('logo-container').style.visibility = 'hidden';
  };

  useEffect(() => {
    handleLoader();
    if (canUseDOM) {
      window.addEventListener('scroll', handleScroll, { passive: true });

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const isIos =
    (canUseDOM && navigator.userAgent.match(/iPhone|iPad|iPod/i) && true) ||
    false;

  const runOnlyIOS = value => {
    if (canUseDOM) {
      if (!!value) {
        document.body.classList.add('drawerOpen');
        document.documentElement.classList.add('drawerOpen');
        setLastScrollPosition(scrollPosition);
      } else {
        document.body.classList.remove('drawerOpen');
        document.documentElement.classList.remove('drawerOpen');
        window.scrollTo({
          top: lastScrollPosition,
        });
      }
    }
  };

  const toggleDrawer = open => event => {
    event.preventDefault();
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    if (open) {
      updateSearchData(false);
    }
    if (isIos) {
      runOnlyIOS(open);
    }
    setIsMenuOpen(open);
  };

  const backButtonText = dictionary('backButton');

  const isEditor = isExperienceEditorActive();

  let hasNotificationBar = canUseDOM && Number(cookie.read('notificationBar'));
  let hasDownloadWidgetBar =
    canUseDOM && Number(getSessionStorage('downloadWidgetBar'));

  useEffect(() => {
    if (canUseDOM) {
      hasNotificationBar = Number(getSessionStorage('notificationBar'));
      hasDownloadWidgetBar = Number(getSessionStorage('downloadWidgetBar'));
      setStickyBarEl(document.querySelector('#stickyBar'));
      if (stickyBarEl) {
        setStickyBarElOffsetTop(stickyBarEl.getBoundingClientRect().top);
      }
      if (appBarAnimate.current) {
        // This was animating again and again on each re-pain or interaction with header i.e opening sub menu or search icon etc.
        // gsap.set(appBarAnimate.current, {
        //   opacity: 0,
        //   y: '-16px',
        // });
        // setTimeout(() => {
        //   gsap.to(appBarAnimate.current, {
        //     opacity: 1,
        //     y: '0px',
        //     duration: 0.250,
        //   });
        // }, 1000);
      }
    }
  });
  // A function to set headerPosition to absolute only if notificationBar exists
  const getHeaderPositionAbsolute = () => {
    if (canUseDOM) {
      if (hasNotificationBar) {
        return 'absolute';
      } else {
        return 'fixed';
      }
    }
  };

  if (isHomePage) {
    // Evaluating viewport height since hero banner is equal to 100vh
    if (canUseDOM) {
      const headerHeight = isMobile ? 80 : 150;
      const scrollPos = window && window.pageYOffset;
      if (!scrollTrigger) {
        isHeaderTransparent = true;
        headerPosition =
          hasNotificationBar || hasDownloadWidgetBar
            ? getHeaderPositionAbsolute()
            : 'fixed';
      } else {
        isHeaderTransparent = scrollPos < headerHeight ? true : false;
      }
    } else {
      isHeaderTransparent = true;
      headerPosition = getHeaderPositionAbsolute();
    }
    classNameHeader =
      isSearchOpen || isMenuOpen
        ? classes.headerWrapper
        : isHeaderTransparent
        ? classes.headerTransparent
        : classes.headerWrapper;
  } else {
    classNameHeader = classes.headerWrapper;
    headerPosition = getHeaderPositionAbsolute();
    if (canUseDOM) {
      headerPosition = !scrollTrigger ? getHeaderPositionAbsolute() : 'fixed';
    }
  }

  const fetchAllFavorites = (contentType, language) => {
    const SolrFilterAPIURL = props.appConfig.SOLR_FAVORITE_SEARCH_API;
    const favData = JSON.parse(getLocalStorage('favorites') || '[]');

    let solrPayloadToFetchFilter = {
      contentType: contentType,
      language: language,
      facetFields: [],
      itemIDs: favData
        .reverse()
        .slice(0, Number(get(favouriteBasket, 'fields["Limit"].value', 3))),
      limit: Number(get(favouriteBasket, 'fields["Limit"].value', 3)),
      offset: 0,
      filters: [],
      isPreviewMode: isEditor,
    };

    props.getFavoritesInNavData({
      apiUrl: `${SolrFilterAPIURL}`,
      payload: solrPayloadToFetchFilter,
    });
  };

  const [stickyBarElOffsetTop, setStickyBarElOffsetTop] = useState(undefined);
  const [stickyBarEl, setStickyBarEl] = useState(undefined);

  useEffect(() => {
    fetchAllFavorites('favorites', language);
  }, [favorites]);

  useEffect(() => {
    if (props.filteredData.favorites) {
      setFavoritesList(props.filteredData.favorites);
      setTotalFavoriteCount(props.filteredData.totalFavorites);
    }
  }, [props.filteredData]);

  useEffect(() => {
    if (canUseDOM) {
      setStickyBarEl(document.querySelector('#stickyBar'));
      if (stickyBarEl) {
        setStickyBarElOffsetTop(stickyBarEl.getBoundingClientRect().top);
      }
    }
  });
  const prevScrollVal = useRef(0);
  const stickyTitleHeight = useRef();

  useEffect(() => {
    if (canUseDOM) {
      const headerEL = document.querySelector('header');
      // ghostdiv is present in StickyTitle.
      const ghostDivEL = document.querySelector('#ghostDiv');
      // Height is as per header styling.
      const headerHeight = isMobile ? 80 : 128;
      function handleScroll() {
        setTimeout(() => {
          if (stickyBarEl) {
            if (
              window.pageYOffset >= stickyBarElOffsetTop &&
              window.pageYOffset !== 0 &&
              ghostDivEL
                ? ghostDivEL.getBoundingClientRect().bottom - headerHeight <= 0
                : true
            ) {
              stickyBarEl.classList.add('sticky');
              if (headerEL && headerEL.getBoundingClientRect().top < 0) {
                stickyBarEl.classList.remove('header-added');
                stickyBarEl.classList.add('header-removed');
              } else {
                stickyBarEl.classList.add('header-added');
                stickyBarEl.classList.remove('header-removed');
              }
            } else {
              stickyBarEl.classList.remove('sticky');
            }
          }
        }, 300);
        if (
          stickyBarEl &&
          window.pageYOffset > prevScrollVal.current &&
          stickyBarEl.classList.contains('header-added')
        ) {
          stickyBarEl.classList.remove('header-added');
          stickyBarEl.classList.add('header-removed');
        }
        if (stickyBarEl && ghostDivEL) {
          // When scrolling down, we are checking the top of ghostdiv.
          // When scrolling up, we are checking the bottom of ghostdiv.
          const flag =
            window.pageYOffset > prevScrollVal.current
              ? ghostDivEL.getBoundingClientRect().top < 0
              : ghostDivEL.getBoundingClientRect().bottom - headerHeight <= 0;
          if (flag) {
            stickyBarEl.classList.remove('ghostdiv-reached');
            ghostDivEL.style.height = stickyTitleHeight.current + 'px';
          } else {
            if (stickyTitleHeight.current === undefined) {
              stickyTitleHeight.current = stickyBarEl.clientHeight;
            }
            stickyBarEl.classList.add('ghostdiv-reached');
            ghostDivEL.style.height = 0;
          }
        }
        prevScrollVal.current = window.pageYOffset;
      }

      window.addEventListener('scroll', handleScroll, {
        capture: true,
        passive: true,
      });
      return () =>
        window.removeEventListener('scroll', handleScroll, {
          capture: true,
          passive: true,
        });
    }
  }, [prevScrollVal.current, stickyBarElOffsetTop, stickyBarEl]);

  const searchClickHandler = () => {
    let val = true;
    if (isMobile) {
      val = !isSearchOpen;
      if (val) {
        setIsMenuOpen(false);
      } else if (canUseDOM) {
        const searchBar = document.querySelector('#search_bar_popup');
        if (
          searchBar &&
          searchBar.classList.contains('MuiAutocomplete-inputFocused')
        ) {
          searchBar.blur();
          if (searchData.data !== val) {
            updateSearchData(val);
          }
          setIsMenuOpen(false);
          setTimeout(() => {
            if (searchData.data !== val) {
              updateSearchData(val);
            }
            setIsMenuOpen(false);
          }, 400);
          return;
        }
      }
    }

    if (searchData.data !== val) {
      updateSearchData(val);
    }
  };
  let isLinkActive = '';
  let pathName = getCurrentPageUrl(props);
  pathName =
    pathName &&
    pathName.split('/') &&
    pathName.split('/').length > 1 &&
    pathName.split('/')[1];
  let isActivePage = get(exploreMapLink, 'value.href', '').split('/')[2];
  if (isActivePage === pathName) {
    isLinkActive = 'isActive';
  } else {
    isLinkActive = '';
  }

  const renderMapSection = () => {
    return (
      <React.Fragment>
        <Link link={exploreMapLink} className={'mapLink'} tabindex={-1}>
          <Button
            iconOnly={true}
            aria-label="map button"
            className={clsx(
              classes.ctaBtn,
              classes.mapIcon,
              'map-icon',
              isLinkActive
            )}
          >
            <DctSvgIcon name={'MapIcon'} />
          </Button>
        </Link>
      </React.Fragment>
    );
  };

  const renderSearchSection = () => {
    return (
      <React.Fragment>
        <Button
          iconOnly={true}
          aria-label="search button"
          onClick={() => searchClickHandler()}
          className={clsx(
            classes.ctaBtn,
            classes.searchIcon,
            'search-icon',
            isSearchOpen && classes.searchOpened,
            {
              [classes.redSearchIcon]: !isSearchPage
                ? !isMobile && isSearchOpen
                : isMobile
                ? !isSearchOpen
                : true,
            }
          )}
        >
          <DctSvgIcon
            name={`${
              isSearchOpen && isMobile ? 'CloseIcon' : 'SearchHeaderIcon'
            }`}
          />
          {isSearchOpen && isMobile && <div className={classes.iconDivider} />}
        </Button>
      </React.Fragment>
    );
  };

  const renderFavoriteSection = () => {
    return (
      <React.Fragment>
        {isMobile && (
          <div className="backBtn" onClick={hideFavoriteSelector}>
            <Button
              component="span"
              buttonType="secondary"
              hasBorder={false}
              isStartIcon={true}
            >
              {backButtonText}
            </Button>
          </div>
        )}

        <FavoriteSection
          favoriteFields={favouriteBasket?.fields}
          favoritesList={favoritesList}
          favoritesCount={totalFavoriteCount}
          googlePlacesAPI={props.appConfig.GOOGLE_PLACES_API}
          language={language}
          isMobile={isMobile}
          dictionary={dictionary}
          itineraryPreviewLink={itineraryPreviewLink}
          closeMobileMenu={setIsMenuOpen}
        />
      </React.Fragment>
    );
  };

  const optionList = [];

  pageLanguageVersions &&
    pageLanguageVersions.map(item => {
      const { compactName = '', url = '', code = '', nativeName = '' } = item;
      const isSelected = language === code ? true : false;

      return optionList.push({
        code: code,
        value: url,
        label: compactName,
        selected: isSelected,
        nativeName: nativeName,
      });
    });

  let selectedLanguage =
    pageLanguageVersions &&
    pageLanguageVersions.filter(lang => language === lang.code);

  let isArabic =
    selectedLanguage &&
    selectedLanguage.length > 0 &&
    selectedLanguage[0].code === 'ar';

  selectedLanguage =
    selectedLanguage &&
    selectedLanguage.length > 0 &&
    selectedLanguage[0].nativeName;

  const logoClickHandler = () => {
    pushAnaylyticsData(analyticsData.headerInteraction.dctLogoClick);
  };

  const handleChangeEvent = (value, code) => e => {
    e.preventDefault();
    if (canUseDOM) {
      // Setting up full URL in cookie with 30 days expiration time on language change
      let langCode = code && code.split('-');
      langCode = langCode.length > 0 && langCode[0];
      cookie.write('_lngpreference', langCode, 30);
      const analyticsInfo = {
        ...analyticsData.headerInteraction.toggleLanguage,
        ...{ label: `${language}|${code}` }, //prev | new language selected
      };
      pushAnaylyticsData(analyticsInfo);
      window.location.href = `${window.location.origin}/${value}${window.location.search}`;
    }
  };

  const showLanguageSelector = () => {
    setLanguageSelectorFlag(true);
  };

  const hideLanguageSelector = () => {
    setLanguageSelectorFlag(false);
  };

  const showFavoritesSelector = () => {
    setFavoriteSelectorFlag(true);
  };

  const hideFavoriteSelector = () => {
    setFavoriteSelectorFlag(false);
  };

  const renderLanguageSelector = () => {
    if (isMobile) {
      return (
        <React.Fragment>
          <div className="backBtn" onClick={hideLanguageSelector}>
            <Button
              component="span"
              buttonType="secondary"
              hasBorder={false}
              isStartIcon={true}
            >
              {backButtonText}
            </Button>
          </div>
          <MenuList>
            {optionList &&
              optionList.map((item, i) => {
                return (
                  <MenuItem key={i}>
                    <Link
                      customHref="#"
                      isCustom={true}
                      className={clsx('flyoutLink', {
                        selected: item && item.selected,
                      })}
                      onClick={handleChangeEvent(
                        item && item.value,
                        item && item.code
                      )}
                    >
                      <Typography variant="h5" component="span">
                        {item && item.nativeName}
                      </Typography>
                      {item && item.selected && <DctSvgIcon name="CheckIcon" />}
                    </Link>
                  </MenuItem>
                );
              })}
          </MenuList>
        </React.Fragment>
      );
    } else {
      return (
        <LanguageSelector
          optionList={optionList}
          handleChangeEvent={handleChangeEvent}
          selectedLanguage={
            isArabic ? selectedLanguage : selectedLanguage.slice(0, 2)
          }
        />
      );
    }
  };

  let headerLogo = '';
  if (isSearchOpen || isMenuOpen) {
    headerLogo = (
      <Image
        onClick={logoClickHandler}
        itemScope
        itemProp="image"
        field={logo}
      />
    );
  } else if (isHeaderTransparent) {
    headerLogo = <Image onClick={logoClickHandler} itemScope itemProp="image" field={isAdLogoWhite ? isAdLogoWhite : alternateLogo} />;
  } else {
    headerLogo = (
      <Image
        onClick={logoClickHandler}
        itemScope
        itemProp="image"
        field={logo}
      />
    );
  }

  if (!fields) return null;

  return (
    <React.Fragment>
      {params.showLoader && (
        <>
          <div id="logo-container" className="itinerary-loader-logo">
            <DctSvgIcon id="loaderlogo" name="LoaderLogo" />
          </div>
          <div id="itinerary-Loader" className="loader--wrapper">
            <div className="itinerary-loader-bg">
              <Picture
                className="placeImage"
                media={{
                  image: ItineraryLoaderBg,
                  disableLazyLoad: true,
                  fallbackBackgroundNeeded: false,
                  aspectRatio: { mobile: '5x4', desktop: '16x8' },
                }}
                isParalax={false}
              />
            </div>
          </div>
        </>
      )}

      {isExploreMapTemplateID && isMobile ? (
        <></>
      ) : (
        <Container
          id="main-header-vad"
          data-locator={COMPONENT_CONTAINER}
          // className={classes.appBarHeader}
          className={`${classes.appBarHeader} `}
        >
          {/* TODO: Need to make href and text dynamic */}
          <Link
            link={{
              value: {
                href: '#root',
                text: 'Skip To Content',
              },
            }}
            id="skip-to-main-content"
            className={classes.skipToMain}
            tabIndex="2"
          >
            <Typography variant="body2Bold" component="div">
              Skip To Content
            </Typography>
          </Link>
          <Slide
            appear={false}
            direction="down"
            in={!trigger}
            data-locator={COMPONENT_SLIDER}
            className={clsx(isSearchOpen && classes.visibleHeader)}
          >
            <AppBar
              classes={{
                root: `${classNameHeader} ${
                  isEditor ? classes.headerEditorMode : ''
                } ${isMenuOpen && classes.megaNavOpened} ${
                  isHeaderTransparent ? 'is-transparent' : ''
                }
                ${isSearchOpen ? 'fixed-header' : ''} ${
                  customStyles.headerPosition
                } ${hideHeader ? customStyles.headerHide : ''}`,
              }}
              data-locator={COMPONENT_HEADER}
              color={`${isHomePage ? 'transparent' : 'secondary'}`}
              position={headerPosition || 'absolute'}
              elevation={0}
              ref={headerAppBar}
            >
              <Grid
                className={classes.root}
                data-locator={COMPONENT_GRID_BLOCK}
                ref={appBarAnimate}
              >
                <Grid
                  container
                  spacing={0}
                  classes={{
                    root: `${classes.root} ${classes.headerContainer}`,
                  }}
                  alignItems="center"
                  data-locator={COMPONENT_GRID_CONTAINER}
                >
                  {logo && (
                    <Grid item xs={3} sm={2} data-locator={COMPONENT_LOGO}>
                      <Link
                        tabIndex="0"
                        className={clsx(classes.brandLogo, 'logo-img')}
                        link={logoLink}
                      >
                        {headerLogo}
                      </Link>
                      {isHeaderTransparent && (
                        <Link
                          tabIndex="0"
                          className={clsx(classes.brandLogo, 'logo-red')}
                          link={logoLink}
                        >
                          <Image onClick={logoClickHandler} field={logo} />
                        </Link>
                      )}
                    </Grid>
                  )}
                  {/* Desktop Section */}
                  <Grid
                    item
                    classes={{ root: `${classes.sectionDesktop}` }}
                    sm={10}
                  >
                    <Placeholder
                      name="vad-global-meganav"
                      rendering={rendering}
                      appConfig={props.appConfig}
                    />
                    <div className={classes.rightSectionWrap}>
                      <div className={classes.rightSection}>
                        {/* {enabledFavorite?.value && renderFavoriteBasket()} */}
                        {isSearchEnabled?.value && renderSearchSection()}
                        {hasFavouriteBasket && renderFavoriteSection()}
                        {renderMapSection()}
                        <div className={`${classes.langSelectorWrap}`}>
                          {pageLanguageVersions && renderLanguageSelector()}
                        </div>
                      </div>
                    </div>
                  </Grid>
                  {/* Mobile Section */}
                  <Grid
                    item
                    classes={{
                      root: `${classes.sectionMobile}`,
                    }}
                    xs={9}
                    sm={10}
                  >
                    <React.Fragment key="left">
                      {/* {enabledFavorite?.value && renderFavoriteBasket()} */}
                      {isSearchEnabled?.value && renderSearchSection()}
                      {renderMapSection()}
                      {isMenuOpen ? (
                        <Button
                          iconOnly={true}
                          aria-label="close drawer"
                          aria-haspopup="false"
                          onClick={toggleDrawer(false)}
                          color="inherit"
                          px={0}
                          className={clsx(classes.menuOpened, classes.ctaBtn)}
                        >
                          <DctSvgIcon name="CloseIcon" />
                          {isMenuOpen && isMobile && (
                            <div className={classes.iconDivider} />
                          )}
                        </Button>
                      ) : (
                        <Button
                          iconOnly={true}
                          edge="start"
                          className={clsx(classes.menuButton, classes.ctaBtn)}
                          aria-label="open drawer"
                          aria-haspopup="true"
                          onClick={toggleDrawer(true)}
                          color="inherit"
                          px={0}
                        >
                          {isSearchOpen ? (
                            <i>{BurgerIconBlack}</i>
                          ) : isHeaderTransparent ? (
                            <i>{BurgerIconWhite}</i>
                          ) : (
                            <i>{BurgerIconBlack}</i>
                          )}
                        </Button>
                      )}
                      <SwipeableDrawer
                        PaperProps={{
                          className: `${classes.menuDrawer} ${
                            customStyles.megaMenuHeight
                          } ${
                            favoriteSelectorFlag ? 'drawerOpen' : ''
                          } mobileMenuDrawer`,
                        }}
                        open={isMenuOpen}
                        onClose={toggleDrawer(false)}
                        onOpen={toggleDrawer(true)}
                        disableBackdropTransition={true}
                        transitionDuration={0}
                      >
                        <List
                          component="ul"
                          aria-label="mobile navigation menu"
                          data-locator={COMPONENT_MOBILE_NAVIGATION}
                        >
                          <ListItem
                            className={classes.navigationListWrap}
                            divider={true}
                          >
                            <Placeholder
                              name="vad-global-meganav"
                              rendering={rendering}
                              appConfig={props.appConfig}
                            />
                          </ListItem>
                          <ListItem className={classes.navigationListWrap}>
                            <Typography
                              variant="h5"
                              component="div"
                              classes={{ root: classes.langSelectorText }}
                              onClick={showLanguageSelector}
                            >
                              <div className="languageLabel">
                                <DctSvgIcon name="Language" />
                                {selectedLanguage}
                              </div>
                              {optionList && optionList.length > 0 && (
                                <DctSvgIcon name="ArrowForwardIosSharp"></DctSvgIcon>
                              )}
                            </Typography>
                            <div
                              className={clsx(
                                classes.flyout,
                                languageSelectorFlag && 'onScreen'
                              )}
                            >
                              {pageLanguageVersions && renderLanguageSelector()}
                            </div>
                          </ListItem>
                          {favouriteBasket && (
                            <React.Fragment>
                              <li className={classes.holdSpaceForFav}></li>
                              <ListItem
                                className={`${classes.footerFavItem} mob-fav-bar`}
                                ref={mobFavBarRef}
                              >
                                <Typography
                                  variant="h5"
                                  component="div"
                                  classes={{ root: classes.favSelectorText }}
                                  onClick={showFavoritesSelector}
                                  animateSettings={{
                                    willAnimate: false,
                                  }}
                                >
                                  <div className="favrLabel">
                                    <DctSvgIcon name="FavoriteBorderIcon" />
                                    <span>
                                      {getDictionaryText('favorites')}
                                    </span>
                                    {totalFavoriteCount > 0 && (
                                      <Badge
                                        badgeContent={totalFavoriteCount}
                                        max={999}
                                        color="secondary"
                                        anchorOrigin={{
                                          horizontal: 'left',
                                          vertical: 'top',
                                        }}
                                      ></Badge>
                                    )}
                                  </div>
                                </Typography>
                                <div
                                  className={clsx(
                                    classes.flyout,
                                    // favoriteSelectorFlag && 'onScreen'
                                    favoriteSelectorFlag && 'onScreen headFav'
                                  )}
                                >
                                  {hasFavouriteBasket &&
                                    favoritesList &&
                                    renderFavoriteSection()}
                                </div>
                              </ListItem>
                            </React.Fragment>
                          )}
                        </List>
                      </SwipeableDrawer>
                    </React.Fragment>
                  </Grid>
                </Grid>
              </Grid>
            </AppBar>
          </Slide>
          {!isHomePage && (
            <div
              className={classes.headerContainerPlaceholder}
              ref={headerAppBarSpacer}
            />
          )}
          {enableSearch && (
            <Placeholder
              name="vad-global-search"
              rendering={rendering}
              appConfig={props.appConfig}
            />
          )}
        </Container>
      )}
    </React.Fragment>
  );
};

export const mapDispatchToProps = dispatch => {
  return {
    updateSearchData: params => dispatch(updateSearchData(params)),
    getFavoritesInNavData: params => dispatch(getFavoritesInNavData(params)),
  };
};

export const mapStateToProps = createStructuredSelector({
  searchData: searchData,
  filteredData: filteredData,
});

export default React.memo(
  withErrorBoundary(
    withSitecoreContext()(
      withNamespaces()(
        connect(mapStateToProps, mapDispatchToProps)(HeaderComponent)
      )
    )
  )
);

export { HeaderComponent as HeaderComponentTest };
